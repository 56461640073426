import React from "react"
import Img from "gatsby-image"

class Cover extends React.Component {
    render() {
        const { node, tag } = this.props
        const { featured_media } = node

        let imageClassNames = "block absolute left-0 top-0 w-full h-full gradient"
        let sources = null

        if (featured_media && featured_media.localFile) {
            const image = featured_media.localFile.childImageSharp

            sources = image.fluid

            if ("desktop" in image) {
                sources = [{
                    ...image.fluid,
                    media: `(max-width: 767px)`
                }]

                sources.push({
                    ...image.desktop,
                    media: `(min-width: 768px)`
                })

                imageClassNames += " image-has-desktop"
            }
        }

        return (
            <div className="relative rounded-lg overflow-hidden pt-9/8 h-0 bg-gray-800 shadow-lg md:pt-9/16">
                {sources &&
                    <figure className={imageClassNames}>
                        <Img fluid={sources} alt={featured_media.alt_text} title={featured_media.title} />
                    </figure>
                }
                <div className="absolute inset-0 p-6 text-white flex flex-col justify-end items-start">
                    {tag &&
                    <div className="flex-1">
                        <span className="bg-primary inline-block py-1 px-2 tiny">{tag}</span>
                    </div>
                    }
                    <div className="w-full md:max-w-1/2">
                        <h2 className="text-2xl text-white xs:text-3xl mb-3" dangerouslySetInnerHTML={{ __html: node.title }} />
                        <div className="text-sm leading-snug" dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Cover
