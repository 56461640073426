import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Tags from "../utils/tags"

export default props => (
    <StaticQuery
        query={graphql`
            query EventtypesQuery {
                allWordpressWpEventtypes(filter: {count: {gte: 0}}) {
                    edges {
                        node {
                            name
                            wordpress_id
                        }
                    }
                }
            }
        `}
        render={data => {
            const { children, ...other } = props
            const tags = data.allWordpressWpEventtypes.edges.map(({ node }) => {
                return {
                    key: node.wordpress_id,
                    label: node.name
                }
            })

            return (
                <Tags tags={tags} handleTagSelect={props.handleSelect} activeTag={props.activeTag} allowHtml={true} {...other}>
                    {children && <>{children}</>}
                </Tags>
            )
        }}
    />
)
