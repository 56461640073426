import React from "react"
import { graphql, navigate, Link } from "gatsby"
import Glide from "@glidejs/glide"
import "@glidejs/glide/dist/css/glide.core.min.css"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import EventTypes from "../components/events/types"
import Cover from "../components/utils/cover"
import StoreList from "../components/stores/list"

class IndexPage extends React.Component {

    eventtypeSelected = (eventtype) => {
        navigate("/events", {
            state: { activeEventtype: eventtype }
        });
    }

    componentDidMount() {
        new Glide('.glide', {
            bound: true,
            gap: -16,
            peek: { before: 0, after: 24 },
            perView: 1,
            classes: {
                activeNav: "bullet--active"
            }
        }).mount()
    }

    render() {
        const {
            allWordpressWpStories,
            allWordpressWpStores
        } = this.props.data

        return (
            <Layout>
                <SEO title="Goldfieber | Das Beste aus Nagold" />
                <h1 className="screen-reader-text">Goldfieber</h1>

                <div className="overflow-hidden">
                    <div className="glide container p-0">
                        <div data-glide-el="track" className="glide__track overflow-visible">
                            <ul className="glide__slides">
                                {allWordpressWpStories.edges.map(({node}) => (
                                    <li key={node.id} className="glide__slide p-sm pb-md">
                                        <Link to={`/stories/${node.slug}`} className="block link">
                                            <Cover node={node} tag="Story" />
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="flex justify-center -mt-6 mb-4" data-glide-el="controls[nav]">
                            {allWordpressWpStories.edges.map(({ node }, i) => (
                                <button key={"bullet-" + i} className="block bullet w-4 h-4" data-glide-dir={"=" + i} aria-label={`Bild Nr. ${(i + 1)} anzeigen`}></button>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="container narrow">
                    <header className="flex justify-between items-center mt-sm mb-0">
                        <h2 className="smaller my-0">Worauf hast du Lust?</h2>
                        <Link to="/events" className="inline-block py-1 hover:border-0">Kalender anzeigen</Link>
                    </header>
                    <EventTypes handleSelect={this.eventtypeSelected} />
                </div>

                <div className="container narrow">
                    <header className="flex justify-between items-center mt-sm mb-0">
                        <h2 className="smaller my-0">Geschäfte</h2>
                        <Link to="/stores" className="inline-block py-1 hover:border-0">Alle anzeigen</Link>
                    </header>
                    <StoreList stores={allWordpressWpStores.edges} />
                </div>
            </Layout>
        )
    }
}

export default IndexPage

export const query = graphql`
    query HomepageQuery {
        allWordpressWpStories(limit: 10, sort: {fields: date, order: DESC}) {
            edges {
                node {
                    id
                    title
                    slug
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 767, maxHeight: 863) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                                desktop: fluid(maxWidth: 1024, maxHeight: 576) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        alt_text
                        title
                    }
                    excerpt
                }
            }
        }
        allWordpressWpStores(
            sort: {fields: [acf___featured, date], order: [DESC, DESC]},
            limit: 5,
            filter: {featured_media: {id: {regex: "/.+/"}}}
        ) {
            edges {
                node {
                    id
                    title
                    slug
                    featured_media {
                        localFile {
                            childImageSharp {
                                fixed(width: 64, height: 64) {
                                    ...GatsbyImageSharpFixed_withWebp
                                }
                            }
                        }
                    }
                    acf {
                        address
                        owner
                        geo {
                            latitude
                            longitude
                        }
                        opening_hours {
                            mon {
                                opened
                                time {
                                    from
                                    to
                                }
                            }
                            tue {
                                opened
                                time {
                                    from
                                    to
                                }
                            }
                            wed {
                                opened
                                time {
                                    from
                                    to
                                }
                            }
                            thu {
                                opened
                                time {
                                    from
                                    to
                                }
                            }
                            fri {
                                opened
                                time {
                                    from
                                    to
                                }
                            }
                            sat {
                                opened
                                time {
                                    from
                                    to
                                }
                            }
                            sun {
                                opened
                                time {
                                    from
                                    to
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
