import React, { Component } from "react"

class Tag extends Component {
    render() {
        const { handleTagSelect, tag, activeTag, disabledState, innerRef, allowHtml } = this.props

        let classes = [
            "flex-none",
            "font-bold",
            "text-white",
            "rounded-md",
            "inline-block",
            "whitespace-no-wrap",
            "p-4"
        ]

        if (activeTag && activeTag.key === tag.key) {
            classes.push("bg-primary")
        } else if (activeTag || disabledState) {
            classes.push("bg-gray-500")
        } else {
            classes.push("bg-primary")
        }

        return (
            <li className="mx-1" ref={innerRef}>
                <button className={classes.join(" ")} onClick={e => handleTagSelect(e, innerRef ? innerRef.current : null)}>{allowHtml ? <span dangerouslySetInnerHTML={{ __html: tag.label }} /> : tag.label}</button>
            </li>
        )
    }
}

export default React.forwardRef((props, ref) => <Tag innerRef={ref} {...props} />);
