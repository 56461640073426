import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default ({ title, children, ...other }) => (
    <div className="flex flex-col justify-center items-center min-h-screen-1/3" {...other}>
        <div className="text-center py-8 mx-auto px-sm max-w-2/3">
            <span className="inline-block text-primary text-4xl relative shake">
                <FontAwesomeIcon icon={["fal", "surprise"]} />
            </span>
            <h3>{title ? title : "Nichts gefunden"}</h3>
            {children &&
                <>{children}</>
            }
        </div>
    </div>
)
