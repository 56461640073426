import React, { Component } from "react"
import Tag from "./tag"

class Tags extends Component {
    state = {
        activeTag: null
    }

    constructor(props) {
        super(props);

        this.handleTagSelect = this.handleTagSelect.bind(this)
        this.scrollTo = this.scrollTo.bind(this)

        this.scrollerRef = React.createRef()
        this.activeTagRef = React.createRef()
    }

    componentDidUpdate(prevProps, prevState) {
        const { activeTag, activeTagKey, tags } = this.props

        if (activeTagKey && (!this.state.activeTag || !prevProps.activeTagKey || activeTagKey !== prevProps.activeTagKey)) {
            this.setState({
                activeTag: tags.find(element => element.key === activeTagKey)
            })
        } else if (activeTag && (!this.state.activeTag || !prevProps.activeTag || activeTag.key !== prevProps.activeTag.key)) {
            this.setState({
                activeTag: activeTag
            })
        }

        if (prevState.activeTag !== activeTag && this.scrollerRef.current && this.activeTagRef.current) {
            this.scrollTo(this.activeTagRef.current)
        }
    }

    scrollTo(tag) {
        if (this.scrollerRef.current && tag) {
            this.scrollerRef.current.parentElement.scrollLeft = parseInt(tag.offsetLeft, 10) - 32
        }
    }

    handleTagSelect = (tag, el) => {
        let newActiveTag = tag

        if (this.state.activeTag && this.state.activeTag.key === tag.key)
            newActiveTag = null

        this.setState({ activeTag: newActiveTag })

        if ("handleTagSelect" in this.props) {
            this.props.handleTagSelect(newActiveTag)
        }
    }

    render() {
        const self = this

        const { tags, className, children, disabledState, allowHtml } = this.props
        const { activeTag } = this.state

        const classNames = ['relative -mx-sm my-4 overflow-hidden', className].join(' ')

        return (
            <div className={classNames}>
                <div className="-my-2 py-1 pb-8 -mb-8 overflow-x-scroll scrolling-touch md:pr-16">
                    <ul className="inline-flex py-1 px-3 relative" ref={this.scrollerRef}>
                        {children &&
                            <>{children}</>
                        }
                        {tags.map((tag) => (
                            <Tag key={tag.key} tag={tag} handleTagSelect={(e, el) => this.handleTagSelect(tag, el)} activeTag={activeTag} disabledState={disabledState} ref={activeTag && activeTag.key === tag.key ? self.activeTagRef : null} allowHtml={allowHtml} />
                        ))}
                    </ul>
                    <div className="hidden absolute inset-y-0 right-0 w-16 fade-out-right md:block" />
                </div>
            </div>
        )
    }
}

export default Tags
